import React from 'react';
import Layout from '../../components/layout';

const TermsOfUse = () => {
  return (
    <Layout
      title={'Terms and Conditions | Discover Profile'}
      metaName={'description'}
      metaContent='These are the Terms and Conditions governing the use of this website. These Terms and Conditions set out the rights and obligations of all users regarding the use of discoverprofile.com'
      small
    >
      <div className="page-hero">
        <h1 className="page-title">Terms and Conditions</h1>
        <h3 className="page-subtitle">
          These are the Terms and Conditions governing the use of this website. These Terms and Conditions set out the
          rights and obligations of all users regarding the use of the website.
        </h3>
      </div>
      <p>
        Your access to and use of the website is conditioned on your acceptance of and compliance with these Terms and
        Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the website.
      </p>
      <p>
        By accessing or using the website you signify your agreement to follow all the Terms and Conditions set forth on
        this page:
      </p>
      <ul>
        <li>
          We respect the intellectual property rights of others. You may not infringe the copyright, trademark or other
          proprietary information of any party.
        </li>
        <li>
          All of the media data downloaded via the website is only for personal use. You bear full responsibility for
          all actions related to the data.
        </li>
        <li>
          The website may contain links to third-party websites or services that are not owned or controlled by us. We
          do not have control over, nor bear the responsibility for the content, privacy policies, or practices of any
          third party web sites or services. We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </li>
        <li>
          We reserve the right to change the terms and conditions from time to time. When the changes are made, we will
          post the revised terms here. You understand and agree that if you use the website after the date on which the
          applicable terms have changed, your use constitutes acceptance of the updated terms.
        </li>
        <li>
          The website is provided to you as is and with all faults and defects without warranty of any kind. Without
          limitation to the foregoing, the website provides no warranty or undertaking, and makes no representation of
          any kind that the website will meet your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption, meet any performance
          or reliability standards or be error free or that any errors or defects can or will be corrected.
        </li>
        <li>We reserve the right to change or discontinue any of the services provided by the website at any time.</li>
      </ul>
    </Layout>
  );
};

export default TermsOfUse;
