import React, { useState } from 'react';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import './styles.scss';

const ContactPage = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async e => {
    e.preventDefault();
    let error = false;
    setLoading(true);
    //REQUEST TO SEND THE EMAIL SHOULD BE PLACED HERE
    setLoading(false);
    if (error) {
      setFormData({ ...formData, error: true, errorMessage: 'Something went wrong, please try again.' });
    } else {
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = event => {
    const target = event.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async () => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    // toggleLastURL()
  };

  return (
    <Layout
      title="Contact Customer Support | Discover Profile"
      metaName="description"
      metaContent="Contact our customer care support and get all the necessary information regarding our service. Discover profile customer support will help you out!"
      small
    >
      {/* <Modal
        isOpen={formData.isModalOpen}
        modalToggle={modalToggle}
        title="Your message has been sent, we will come back to you shortly."
        onButtonClick={modalToggle}
        buttonText="Close"
      /> */}
      <div className="contact-block">
        <div className="main container">
          <div className="page-hero">
            <h1 className="page-title">Feel free to contact us</h1>
          </div>
          <form className="contact_form_box" onSubmit={e => !loading && submitHandler(e)}>
            <div className="row">
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder="Your name"
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder="Your email"
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder="Your Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <textarea
                  required
                  className="textarea"
                  rows={5}
                  onChange={changeHandler}
                  name="message"
                  placeholder="Type your message here"
                  value={formData.message}
                />
              </div>
              <div className="col-lg-12 footer-block">
                <div className="my-auto mb-md-2">
                  You can also contact me via email at{' '}
                  <a href="mailto:info@discoverprofile.com" aria-label="mail" target="_blank" rel="noopener">
                    info@discoverprofile.com
                  </a>
                </div>
                <button className="button ml-auto" disabled={loading}>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
